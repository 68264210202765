// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuItemCategorie__container {
  width: 100%;
  display: inline-flex;
}
.menuItemCategorie__container .menuItemCategorie__color {
  margin: 5px 7px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 12px;
}
.menuItemCategorie__container .menuItemCategorie__title {
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.menuItemCategorie__container .menuItemCategorie__showAllCat {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  opacity: 0.25;
  text-align: center;
}
.menuItemCategorie__modal {
  width: 100%;
  display: inline-flex;
  padding: 10px 0 10px 3px;
}
.menuItemCategorie__modal .menuItemCategorie__color {
  margin: 1px 10px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 12px;
}
.menuItemCategorie__modal .menuItemCategorie__title {
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/ListRow/Categories.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,oBAAA;AACJ;AAHA;EAKQ,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACR;AATA;EAYQ,0BAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAfA;EAmBQ,WAAA;EACA,qCAAA;EACA,aAAA;EACA,kBAAA;AADR;AAKA;EACI,WAAA;EACA,oBAAA;EACA,wBAAA;AAHJ;AAAA;EAMQ,oBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAHR;AANA;EAaQ,0BAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AAJR","sourcesContent":[".menuItemCategorie__container {\n    width: 100%;\n    display: inline-flex;\n\n    .menuItemCategorie__color {\n        margin: 5px 7px 0 0;\n        width: 12px;\n        height: 12px;\n        border-radius: 12px;\n    }\n\n    .menuItemCategorie__title {\n        text-transform: capitalize;\n        text-overflow: ellipsis;\n        overflow: hidden;\n        white-space: nowrap;   \n    }\n\n    .menuItemCategorie__showAllCat {\n        width: 100%;\n        border: 1px solid rgba(0,0,0, .15);\n        opacity: .25;\n        text-align: center;\n    }\n}\n\n.menuItemCategorie__modal {\n    width: 100%;\n    display: inline-flex;\n    padding: 10px 0 10px 3px;\n\n    .menuItemCategorie__color {\n        margin: 1px 10px 0 0;\n        width: 20px;\n        height: 20px;\n        border-radius: 12px;\n    }\n\n    .menuItemCategorie__title {\n        text-transform: capitalize;\n        text-overflow: ellipsis;\n        overflow: hidden;\n        white-space: nowrap;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
