// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  height: 100%;
  line-height: 1.5715;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.85);
  background-color: #d9d6cb;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(17px + 1vmin);
  letter-spacing: 1.5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';
}

.App a:link,
a:visited {
  text-decoration: none !important;
  color: rgba(0, 0, 0, .75);
}

.App a:hover {
  color: rgba(229, 152, 102, .75);
  text-decoration: none !important;

}

.link {
  color: rgba(229, 152, 102, 1);
  cursor: pointer;
  text-decoration: none !;
}

.link:hover {
  color: rgba(229, 152, 102, .75);
}

.ant-dropdown-menu {
  border-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,kMAAkM;EAClM,0BAA0B;EAC1B,6BAA6B;EAC7B,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,qBAAqB;EACrB,0DAA0D;AAC5D;;AAEA;;EAEE,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,gCAAgC;;AAElC;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["html,\nbody {\n  margin: 0;\n  height: 100%;\n  line-height: 1.5715;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';\n  font-variant: tabular-nums;\n  font-feature-settings: 'tnum';\n  color: rgba(0, 0, 0, 0.85);\n  background-color: #d9d6cb;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(17px + 1vmin);\n  letter-spacing: 1.5px;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';\n}\n\n.App a:link,\na:visited {\n  text-decoration: none !important;\n  color: rgba(0, 0, 0, .75);\n}\n\n.App a:hover {\n  color: rgba(229, 152, 102, .75);\n  text-decoration: none !important;\n\n}\n\n.link {\n  color: rgba(229, 152, 102, 1);\n  cursor: pointer;\n  text-decoration: none !;\n}\n\n.link:hover {\n  color: rgba(229, 152, 102, .75);\n}\n\n.ant-dropdown-menu {\n  border-radius: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
